import { ModelService } from "./base";

export class CareerService extends ModelService<any> {
  constructor() {
    super("career");
  }

  sendApplication = (payload: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    resumeurl: string;
    coverletter: string;
    filetype: string;
  }) => {
    return this.api
      .post<{ data: any }>(`/${this.modelName}`, payload)
      .then(({ data }) => data);
  };

  //   get secured url from s3
  getSecuredUrl = (payload: {
    filetype: string;
    name: string;
  }) => {
    return this.api
      .post<{
        uploadLink: string;
        status: boolean;
      }>(`/s3`, payload)
      .then(({ data }) => data);
  };

  sendFile = (payload: {url: string, file: File}) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    return this.api.put(payload.url, formData).then(({ data }) => data);
  };
}

export const careerService = new CareerService();
