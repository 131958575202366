import { Meal, MealData } from "../model";
import { ModelService } from "./base";

export class MealService extends ModelService<Meal> {
  constructor() {
    super("products");
  }

  readAllMeal = (payload: {
    pageSize?: number;
    pageNumber?: number;
    filter?: string;
  }) => {
    const queryString = this.buildQueryString({pageSize: payload.pageSize, pageNumber: payload.pageNumber} as Record<never, never>);
    if (payload.filter) {
      return this.api
        .get<{ data: MealData }>(`/${this.modelName}${queryString}&filter=${payload.filter}`)
        .then(({ data }) => data.data);
    }
    return this.api
      .get<{ data: MealData }>(`/${this.modelName}${queryString}`)
      .then(({ data }) => data.data);
  };
}

export const mealService = new MealService();
