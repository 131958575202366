import { Box, HStack, Heading, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";

import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

interface EatWellWayCardProps {
    imageUrl: string;
    title: string;
    description: string;
    link?: string;
}

export const EatWellWayCard = (props: EatWellWayCardProps) => {
    const { imageUrl, title, description, link } = props;
  return (
    <>
      {
        link ? (
          <Box as={Link} to={link} w="full" rounded="10px" overflow="hidden" boxShadow='md' borderWidth="1px" borderColor="border.card">
            <Image
              src={imageUrl}
              alt={title}
              w="full"
              h={["13.5rem", "15rem", "17.5rem"]}
              objectFit="cover"
            />
            <Box bg="white" p="1.25rem">
              <HStack>
                <Heading color="text.black" fontSize={["1.25rem", "1.75rem"]} fontWeight={700}>{title}</Heading>
                <Icon as={HiArrowNarrowRight} fontSize={["1rem", "1.5rem"]} />
              </HStack>

              <Text mt="10px" fontWeight={300} fontSize={["lg", "xl"]} color="text.body">
                {description}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box w="full" rounded="10px" overflow="hidden" boxShadow='md' borderWidth="1px" borderColor="border.card">
            <Image
              src={imageUrl}
              alt={title}
              w="full"
              h={["13.5rem", "15rem", "17.5rem"]}
              objectFit="cover"
            />
            <Box bg="white" p="1.25rem">
              <HStack>
                <Heading color="text.black" fontSize={["1.25rem", "1.75rem"]} fontWeight={700}>{title}</Heading>
                <Icon as={HiArrowNarrowRight} fontSize={["1rem", "1.5rem"]} />
              </HStack>

              <Text mt="10px" fontWeight={300} fontSize={["lg", "xl"]} color="text.body">
                {description}
              </Text>
            </Box>
          </Box>
        )
      }
    </>
  );
};
