import {
  Box,
  Button,
  Container,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";

import ServiceBg from "../../assets/images/lifestyle.jpg";
import { Title } from "../../components";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Services = () => {
  return (
    <Box w="full" bg="white">
      {/* mobile view */}
      <Box display={["block", "none"]} pos="relative">
        <Image
          src={ServiceBg}
          alt="our services"
          w="full"
          h="586px"
          objectFit="cover"
        />

        <Box
          py="1.25rem"
          px="1.25rem"
          bg="servicesCardBg"
          maxW="300px"
          roundedTopLeft="10px"
          roundedBottomRight="10px"
          pos="absolute"
          top="40px"
          right="0"
        >
          <Title
            fontSize="1.65rem"
            text="The Eatwell Lifestyle"
            color="white"
          />

          <Text
            color="white"
            fontWeight={300}
            fontSize="sm"
            mt="0.75rem"
            mb="1rem"
            lineHeight={1.5}
          >
            Enjoy the relishing experience of real African dishes at Eatwell
            Philly with an offering of authentic feeling of home, complemented
            with an eclectic African beverage list.<br /> Eatwell Philly is your go to
            food hub in the heart of North Eastern USA.
          </Text>

          <Button
            bg="header.grayBg"
            _hover={{ bg: "secondary.200", color: "secondary.700" }}
            color="white"
            fontSize={["md", "1.25rem"]}
            p={["1.25rem", "2rem"]}
            py={["1.75rem", "2rem"]}
            rightIcon={<Icon as={BsArrowUpRight} />}
            as={Link}
            to="/reservations"
          >
            Make A Reservation
          </Button>
        </Box>
      </Box>
      <Container
        display={["none", "block"]}
        maxW="container.xl"
        py="5rem"
        pos="relative"
      >
        <Image
          src={ServiceBg}
          alt="our services"
          w="full"
          h="700px"
          objectFit="cover"
          rounded="10px"
          roundedBottomRight="0"
        />

        <Box
          py="3rem"
          px="4rem"
          bg="servicesCardBg"
          maxW="774px"
          roundedTopLeft="10px"
          roundedBottomRight="10px"
          pos="absolute"
          top="120px"
          right="16px"
        >
          <Title text="The Eatwell Lifestyle" color="white" />

          <Text
            color="white"
            fontWeight={300}
            fontSize="xl"
            mt="2rem"
            mb="2.5rem"
            lineHeight={1.5}
          >
            Enjoy the relishing experience of real African dishes at Eatwell
            Philly with an offering of authentic feeling of home, complemented
            by an eclectic African beverage list. <br />
            Eatwell Philly is your food hub from the heartbeat of North Eastern
            USA.
          </Text>

          <Button
            bg="header.grayBg"
            _hover={{ bg: "secondary.200", color: "secondary.700" }}
            color="white"
            fontSize="1.25rem"
            p="2rem"
            rightIcon={<Icon as={BsArrowUpRight} />}
            as={Link}
            to="/reservations"
          >
            Make A Reservation
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
