import React from 'react'
import { MainLayout } from '../layouts'
import { EatWellWay, Hero, LocateUs, Services, Signature } from '../features'
import { MeetChef } from '../features/home/MeetChef'

export const Home = () => {
  return (
    <MainLayout>
        <Hero />
        <EatWellWay />
        <Signature />
        <Services />
        <MeetChef />
        <LocateUs />
    </MainLayout>
  )
}
