/**
 * Build query string from object keys & values
 *
 * @param params
 */
export const buildQueryString = (
    params?: Record<string | number | symbol, string | string[] | null | number>
  ): string => {
    const paramsArray: string[] = [];
    if (params && typeof params === "object") {
      Object.keys(params).forEach((key) => {
        const param = params[key];
        if (param && Array.isArray(param)) {
          for (const paramArrItem of param) {
            paramsArray.push(`${key}[]=${paramArrItem}`);
          }
        }
        if (param && !Array.isArray(param)) {
          paramsArray.push(`${key}=${param}`);
        }
      });
    }
  
    return paramsArray?.length ? `?${paramsArray.join("&")}` : "";
  };
  
  /**
   * Search URL params
   */
  export const getQueryParams = (): any => {
    return new URLSearchParams(window ? window.location.search : {});
  };
  