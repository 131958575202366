import { Box, Container, Grid, GridItem, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Title } from "../../components";

import MeetChefImage from "../../assets/images/meet-us.jpg"
import MeetChefImageMobile from "../../assets/images/meet-us-mobile.png"

export const MeetChef = () => {
  return (
    <Box bg="white">
      <Container maxW="container.xl" py={["2rem", "5rem"]}>
        <Grid templateColumns={["100%", "1fr 535px"]} gap={6}>
          <GridItem>
            <Title text="Meet Chef Aisha" />
            <Heading color="#5E5E5E" fontSize={["1.3rem", "1.75rem"]} fontWeight={400} pl={["0", "2.5rem"]}>CEO & Executive Chef</Heading>

            <Image
              display={["block", "none"]}
              src={MeetChefImageMobile}
              alt="meet chef aisha"
              w="full"
              height="auto"
              pt={[6, 0]}
            />

            <VStack w="full" spacing={4} mt={[6, 4]}>
              <Text color="text.paragraph" fontSize="xl" fontWeight={300}>
                From my earliest days in Potiskum, Nigeria, the aroma of family
                recipes and the allure of the restaurant world wove themselves
                into my destiny. Born into a lineage where culinary artistry
                flowed through our veins, I couldn't help but be captivated by
                the dance of flavors and the symphony of tastes.
              </Text>
              <Text color="text.paragraph" fontSize="xl" fontWeight={300}>
                As the years unfolded, my journey led me through the intricate
                tapestry of various food industries, where I honed my skills and
                deepened my passion. Over the span of three decades, I crafted
                moments of culinary magic, catering to a kaleidoscope of events
                – weddings alive with love, birthdays brimming with joy, and a
                myriad of social gatherings that painted stories of
                togetherness.
              </Text>
              <Text color="text.paragraph" fontSize="xl" fontWeight={300}>
                Nestled in the heart of Irvington, NJ, Eatwell emerged as a
                testament to my unwavering dedication. However, as the chapters
                of my culinary saga unfolded, I felt a whisper in my heart
                urging me to extend my culinary canvas to new horizons.
              </Text>
              <Text color="text.paragraph" fontSize="xl" fontWeight={300}>
                Philadelphia beckoned with its open arms and diverse spirit, and
                the time felt ripe to bring the true essence of Nigerian cuisine
                to its bustling streets. With the memories of my roots, the
                lessons of a lifetime, and the flavors of my heritage, I
                embarked on a journey to create a haven of authenticity amid the
                vibrant heart of Philly.
              </Text>
              <Text color="text.paragraph" fontSize="xl" fontWeight={300}>
                And so, my restaurant's story began – a journey fueled by family
                traditions, three decades of expertise, and an unshakeable
                desire to share the soulful taste of Nigeria with the City of
                Brotherly Love.
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Image 
                display={["none", "block"]}
                src={MeetChefImage}
                alt="meet chef aisha"
                w="full"
                height="auto"
            />
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};
