export const colors = {
  header: {
    bg: "rgba(2, 48, 71, 0.65)",
    grayBg: "rgba(255, 248, 240, 0.5)",
  },
  primary: {
    500: "#233D4D",
    600: "#1D5876",
  },
  gray: {
    50: "#E2E2E2",
    100: "#D9D9D9",
    200: "#F2F2F2",
    300: "#4F4F4F",
    400: "#717171",
    500: "#404040",
    600: "#B4B4B4",
    700: "#888",
  },
  secondary: {
    50: "#fff7ec",
    100: "#ffecd3",
    200: "#ffd6a5",
    300: "#ffb86d",
    400: "#ff8e32",
    500: "#ff6e0a",
    600: "#FF5400",
    700: "#cc3a02",
    800: "#a12e0b",
    900: "#82280c",
  },
  text: {
    primary: "#121212",
    secondary: "#161616",
    body: "#313131",
    bodyGray: "#FBFBFB",
    footerGray: "#B3B3B3",
    footerText: "#A7A7A7",
    bodyDarkGray: "#929292",
    bodyDarkerGray: "rgba(35, 61, 77, 0.5)",
    paragraph: "#2B2B2B",
  },
  border: {
    card: "#CECECE",
    footer: "rgba(255, 248, 240, 0.3)",
    largeFooter: "#FFF8F0",
    darkInput: "rgba(255, 248, 240, 0.25)",
  },
  servicesCardBg: "rgba(2, 48, 71, 0.8)",
  footerContactBg: "rgba(19, 79, 111, 0.5)",
  navigationArrowBg: "#FFE7DC",
  mapButtonBg: "rgba(2, 48, 71, 0.6)",
  tagGrayBg: "rgba(255, 248, 240, 0.50)",
  tableHeaderBg: "rgba(35, 61, 77, 0.3)",
  inputGrayBg: "rgba(35, 61, 77, 0.05)",
  iconBgTransparent: "rgba(2, 48, 71, 0.5)",
  footerInputBg: "rgba(23, 115, 164, 0.3)",
  filterBg: "rgba(2, 48, 71, 0.42)",
  filterActiveBg: "rgba(255, 248, 240, 0.3)",
};

export type ColorProps = typeof colors;
