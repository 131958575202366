import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Title } from "../../components";
import { EatWellWayCard } from "./EatWellWayCard";

import Way1 from "../../assets/images/way1.jpg";
import Way2 from "../../assets/images/way2.jpg";
import Way3 from "../../assets/images/way3.jpg";
import { About } from "./About";

export const EatWellWay = () => {
  return (
    <Box bg="border.largeFooter" id="services">
      <Container maxW="container.xl" py={["2.5rem", "5rem"]}>
        <Title text="The Eatwell Way" />

        <SimpleGrid columns={[1, 3]} spacing="1.25rem" mt={["1.5rem", "2rem"]}>
          <EatWellWayCard
            imageUrl={Way1}
            title="Take-Out"
            description="Checkout our menu and come to order a takeout at our restaurant"
            // link="/take-out"
          />
          <EatWellWayCard
            imageUrl={Way2}
            title="Dine-In"
            description="Checkout our menu and come to make an order at our restaurant"
            link="/menu"
          />
          <EatWellWayCard
            imageUrl={Way3}
            title="Catering & Events"
            description="Contact us to handle your catering and other event needs"
            link="/events"
          />
        </SimpleGrid>
      </Container>
      <About />
    </Box>
  );
};
