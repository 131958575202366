import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Meal } from "../model";

export const MenuCard = (props: Meal) => {
  const { description, productname } = props;

  // if (description === "") {
  //   return null;
  // }

  return (
    <Box
      bg="filterBg"
      rounded="8px"
      overflow="hidden"
      borderWidth="0.5px"
      borderColor="rgba(255, 248, 240, 0.25)"
      cursor="pointer"
      boxShadow="0px 10px 10px -10px rgba(0, 0, 0, 0.03)"
    >
      <Box p="1.25rem">
        <Heading fontSize={["xl", "3xl"]} fontWeight={400} mb="1rem" color="white">{productname}</Heading>
        <Text color="gray.400" fontWeight={300} fontSize={["md", "2xl"]}>
          {description}
        </Text>
      </Box>
    </Box>
  );
};
