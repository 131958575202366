import React from "react";
import { MainLayout } from "./MainLayout";
import { Box, Container, HStack, Heading, Image } from "@chakra-ui/react";

import HeroPattern from "../assets/images/heroPattern.png";

interface LayoutWithHeadProps extends React.PropsWithChildren {
  rightElement?: React.ReactNode;
  bottomElement?: React.ReactNode;
  title: string;
}

export const LayoutWithHead = (props: LayoutWithHeadProps) => {
  const { children, rightElement, bottomElement, title } = props;

  return (
    <MainLayout>
      <Box bg="primary.500" pos="relative">
        <Image
          src={HeroPattern}
          w="100vw"
          pos="absolute"
          top="0"
          left="0"
          h="100%"
          loading="lazy"
          objectFit={"cover"}
        />

        <Container
          pos="relative"
          zIndex={2}
          maxW="container.xl"
          pt={["6rem", "9rem"]}
          pb="7rem"
        >
          <HStack justifyContent="space-between">
            <Heading color="white" fontSize={["3xl", "4xl", "6xl"]}>
              {title}
            </Heading>

            <Box>{rightElement}</Box>
          </HStack>
          <Box mt="0.75rem">{bottomElement}</Box>
        </Container>
      </Box>
      <Box bg="primary.500" roundedTop={["2rem", "5rem"]} mt="-5rem" pos="relative" zIndex={5}>
        <Container maxW="container.xl" pt={["2rem", "4rem"]} pb="4rem">
          {children}
        </Container>
      </Box>
    </MainLayout>
  );
};
