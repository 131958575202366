import { Box, Button, Center, Container, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const ApplicationSuccess = () => {
  const navigate = useNavigate();
  return (
    <Box bg="gray.200">
      <Container maxW="container.xl" py="5rem" pb="8rem">
        <Center flexDir="column">
          <Text
            textAlign="center"
            fontSize={["3xl", "2rem"]}
            fontWeight={700}
            color="black"
          >
            You have successfully submitted your application 🎊
          </Text>
          <Text
            textAlign="center"
            fontSize={["xl", "3xl"]}
            fontWeight={300}
            color="text.secondary"
            mt={["1rem", "0"]}
            mb={["1.5rem", "2.5rem"]}
          >
            Kindly expect to hear from us soon.
          </Text>

          <Button
            bg="secondary.600"
            _hover={{ bg: "secondary.700" }}
            color="white"
            fontSize={["md", "1.25rem"]}
            p={["1.25rem", "2rem"]}
            py={["1.75rem", "2rem"]}
            rightIcon={<Icon as={BsArrowRight} />}
            onClick={() => navigate("/")}
          >
            Done
          </Button>
        </Center>
      </Container>
    </Box>
  );
};
