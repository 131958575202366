import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { BsArrowRight } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { careerService } from "../../services/career";
import { useNavigate } from "react-router-dom";

interface FormField {
  label: string;
  placeholder: string;
  type: string;
  name:
    | "email"
    | "firstName"
    | "lastName"
    | "phoneNumber"
    | "homeAddress"
    | "coverLetter";
}

const formFields: FormField[] = [
  {
    label: "First name",
    placeholder: "First name",
    type: "text",
    name: "firstName",
  },
  {
    label: "Last name",
    placeholder: "Last name",
    type: "text",
    name: "lastName",
  },
  {
    label: "Email",
    placeholder: "Email address",
    type: "email",
    name: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    type: "tel",
    name: "phoneNumber",
  },
  {
    label: "Home address",
    placeholder: "Home address",
    type: "text",
    name: "homeAddress",
  },
];

const schema = Yup.object({
  firstName: Yup.string().min(2).max(15).required().label("First Name"),
  lastName: Yup.string().min(2).max(15).required().label("Last Name"),
  email: Yup.string().email().required().label("Email Address"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  homeAddress: Yup.string().min(2).required().label("Home Address"),
  coverLetter: Yup.string().optional().label("Cover Letter"),
});

export type CareerFormInputType = Yup.InferType<typeof schema>;

export const ApplicationForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      homeAddress: "",
      coverLetter: "",
    },
    resolver: yupResolver(schema),
  });

  const [url, setUrl] = useState<string>("");
  const [fileInfo, setFileInfo] = useState<{
    fileName: string;
    fileExtension: string;
  }>({
    fileName: "",
    fileExtension: "",
  });

  const [file, setFile] = useState<File | null>(null);

  // Upload resume
  const { mutate: uploadResume, isLoading: uploading } = useMutation(
    careerService.sendFile,
    {
      onSuccess: (data: any) => {
        const values = getValues();

        mutate({
          resumeurl: url.split("?")[0],
          firstname: values.firstName,
          lastname: values.lastName,
          email: values.email,
          phone: values.phoneNumber,
          address: values.homeAddress,
          coverletter: values?.coverLetter || "",
          filetype: fileInfo.fileName.split(".")[1],
        });
      },
      onError: (error: any) => {
        toast({
          title: "Error uploading resume",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 5000,
        } as any);
      },
    }
  );

  // send application
  const { mutate, isLoading } = useMutation(careerService.sendApplication, {
    onSuccess: (data: any) => {
      toast({
        title: "Application sent successfully",
        description: "We will get back to you soon",
        status: "success",
        duration: 5000,
      } as any);
      reset();
      navigate("/career/success")
    },
    onError: (error: any) => {
      toast({
        title: "Error sending application",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 5000,
      } as any);
    },
  });

  const { mutate: createLink, isLoading: creatingLink } = useMutation(
    careerService.getSecuredUrl, {
      onSuccess: (data: any) => {
        setUrl(data.uploadLink)
      },
      onError: (error: any) => {
        toast({
          title: "Error sending application",
          description: error?.response?.data?.message || "An error occurred while trying to upload file",
          status: "error",
          duration: 5000,
        } as any);
      },
    }
  )

  // handle form submit
  useEffect(() => {
    if(!url) return;
    uploadResume({ url: url, file: file as File});
  }, [file, uploadResume, url]);

  // handle image upload
  const handleImageUpload = async (e: any) => {
    
    const file = e.target.files[0];

    if (!file) return;

    // check if file is more than 10mb return an error
    if (file.size > 10000000) {
      toast({
        title: "Error sending application",
        description: "File size should not be more than 10mb",
        status: "error",
        duration: 5000,
      } as any);
      return;
    }

    // check if file is not a pdf or doc file
    if (!file.type.includes("pdf") && !file.type.includes("doc")) {
      toast({
        title: "Error sending application",
        description: "File type should be a pdf or doc file",
        status: "error",
        duration: 5000,
      } as any);
      return;
    }

    setFile(file);
    setFileInfo({ fileName: file.name, fileExtension: file.name.split(".")[1] });
  };

  function onSubmit(values: CareerFormInputType) {
    createLink({name: fileInfo.fileName.split(".")[0], filetype: fileInfo.fileName.split(".")[1]})
  }

  return (
    <Box id="application-form" bg="gray.200">
      <Container maxW="container.xl" py={["2.5rem", "5rem"]}>
        <Heading fontSize={["3xl", "2rem"]} fontWeight={700} color="black">
          Let’s get to know you...
        </Heading>

        <VStack
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          w="full"
          spacing={["1rem", "2rem"]}
          mt="2rem"
        >
          <SimpleGrid w="full" columns={[1, 2]} spacing={["1rem", "2rem"]}>
            {formFields.map((field, index) => (
              <Box w="full">
                <Text
                  w="full"
                  textAlign="left"
                  as="label"
                  fontSize={["xs", "1.35rem"]}
                  fontWeight="light"
                  color="black"
                >
                  {field.label}
                </Text>
                <Input
                  w="full"
                  h={["3rem", "5rem"]}
                  bg="white"
                  rounded="8px"
                  mt="10px"
                  fontSize={["sm", "1.25rem"]}
                  fontWeight={300}
                  type={field.type}
                  placeholder={field.placeholder}
                  {...register(field.name)}
                  isInvalid={!!errors[field.name]}
                />
              </Box>
            ))}
            <Box w="full">
              <Text
                w="full"
                textAlign="left"
                as="label"
                fontSize={["xs", "1.35rem"]}
                fontWeight="light"
                color="black"
              >
                Resume
              </Text>
              <Input
                onChange={handleImageUpload}
                type="file"
                display="none"
                id="file"
              />
              <Box
                w="full"
                h={["3rem", "5rem"]}
                display="flex"
                alignItems="center"
                bg="white"
                rounded="8px"
                mt="10px"
                fontSize={["sm", "1.25rem"]}
                fontWeight={300}
                as="label"
                htmlFor="file"
                px="1rem"
                color={fileInfo.fileName !== "" ? "black" : "gray.400"}
                cursor="pointer"
                pos="relative"
              >
                {fileInfo.fileName !== ""
                  ? fileInfo.fileName
                  : "Attach resume (PDF and DOC files only)"}
              </Box>
            </Box>
          </SimpleGrid>

          <FormControl w="full">
            <Text
              w="full"
              textAlign="left"
              as="label"
              fontSize={["xs", "1.35rem"]}
              fontWeight="light"
              color="black"
            >
              Cover letter
            </Text>
            <Textarea
              w="full"
              h={["12rem", "13.75rem"]}
              bg="white"
              rounded="8px"
              mt="10px"
              fontSize={["sm", "1.25rem"]}
              fontWeight={300}
              pt="1.5rem"
              {...register("coverLetter")}
              isInvalid={!!errors.coverLetter}
            >
              Tell us about yourself
            </Textarea>
            <FormErrorMessage>{errors.coverLetter?.message}</FormErrorMessage>
          </FormControl>

          <HStack w="full">
            <Button
              bg="secondary.600"
              _hover={{ bg: "secondary.700" }}
              color="white"
              fontSize={["md", "1.25rem"]}
              p={["1.25rem", "2rem"]}
              py={["1.75rem", "2rem"]}
              rightIcon={<Icon as={BsArrowRight} />}
              type="submit"
              isLoading={isLoading || uploading || creatingLink}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </Container>
    </Box>
  );
};
