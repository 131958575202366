import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";

import EventsBg from "../../assets/images/eventBanner.png"

export const EventsHero = () => {
  return (
    <Box bg="white" mt={["2rem", "6rem"]}>
      <Container maxW="container.xl" py="5rem" pb="5rem">
        <SimpleGrid alignItems="center" columns={[1, 2]} gap="8">
          <Box>
            <Heading color="black" fontSize={["4xl", "7xl"]} fontWeight={700}>
              Catering & Events
            </Heading>

            <Text
              mt="0.5rem"
              maxW="560px"
              fontSize={["lg", "3xl"]}
              fontWeight={300}
              lineHeight={1.5}
              mb="2.5rem"
            >
              At Eatwell Philly, we are dedicated to giving the best service for
              your events. You can reach out to our rep to confirm details of
              your needs
            </Text>

            <HStack spacing="1rem">
              <Button
                as={Link}
                href="tel: +1 215-964-9213"
                bg="secondary.600"
                _hover={{ bg: "secondary.700" }}
                color="white"
                fontSize={["md", "1.25rem"]}
                p={["1.25rem", "2rem"]}
                py={["1.75rem", "2rem"]}
                rightIcon={<Icon transform="rotate(260deg)" as={IoCallOutline} />}
              >
                Call Us
              </Button>
              <Button
                as={Link}
                href="mailto: food@eatwellphilly.com"
                bg="mapButtonBg"
                _hover={{ bg: "secondary.200", color: "secondary.700" }}
                color="white"
                fontSize={["md", "1.25rem"]}
                p={["1.25rem", "2rem"]}
                py={["1.75rem", "2rem"]}
                rightIcon={<Icon as={IoMailOutline} />}
              >
                Send Email
              </Button>
            </HStack>
          </Box>

          <Image display={["block"]} src={EventsBg} w="full" maxW="535px" loading="lazy" />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
