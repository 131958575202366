import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsArrowUpRight } from "react-icons/bs";

import CareerImage from "../../assets/images/career-hero.jpg";

export const CareerHero = () => {
  return (
    <Box pt="7rem" bg="white">
      <Container maxW="container.xl" py="8">
        <HStack flexDir={["column", "row"]} spacing="2rem" alignItems="flex-start" justifyContent="space-between">
          <Box pt={["1rem", "4rem"]}>
            <Heading color="black" fontSize={["4xl", "7xl"]} fontWeight={700}>
              Careers
            </Heading>

            <Text
              mt="0.5rem"
              maxW="560px"
              fontSize={["lg", "1.5rem"]}
              fontWeight={300}
              lineHeight={1.5}
              mb="2.5rem"
            >
              At Eatwell Philly, we provide opportunities for people to join and
              work with us. Are you interested in being a part of the family?
              Apply today!
            </Text>

            <Button
              bg="text.primary"
              _hover={{ bg: "secondary.700" }}
              color="white"
              fontSize={["md", "1.25rem"]}
              p={["1.25rem", "2rem"]}
              py={["1.75rem", "2rem"]}
              rightIcon={<Icon as={BsArrowUpRight} />}
              as={Link}
              href="/career#application-form"
            >
              Apply Now
            </Button>
          </Box>

          <Image src={CareerImage} w="full" maxW="535px" loading="lazy" />
        </HStack>
      </Container>
    </Box>
  );
};
