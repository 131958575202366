import React from 'react'
import { MainLayout } from '../layouts'
import { EventsHero } from '../features/events'

export const Events = () => {
  return (
    <MainLayout>
        <EventsHero />
    </MainLayout>
  )
}
