import { FC } from "react";
import { useState,  useEffect } from "react";
import { Marker, Popup, useMapEvents,useMap } from "react-leaflet";

type Props = {
	value: boolean;
};
export const LocationMarker: FC<Props> = (props) => {
    const mapInstance = useMap()
	//const locatMapRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
          mapInstance.invalidateSize();
        }, 100);
      }, [mapInstance]);
	const [position, setPosition] = useState<any>([51.505, -0.09]);
	const map = useMapEvents({
		click() {
			setPosition([
				Number((39.948039969988535).toFixed(4)),
				Number((-75.14274443448495).toFixed(4)),
			]);
			map.flyTo(
				[
					Number((39.948039969988535).toFixed(4)),
					Number((-75.14274443448495).toFixed(4)),
				],
				map.getZoom()
			);
		},
		locationfound(e: any) {
			setPosition(e.latlng);
		},
	});

	useEffect(() => {
		if (props.value) {
			setPosition([
				Number((39.948039969988535).toFixed(4)),
				Number((-75.14274443448495).toFixed(4)),
			]);
			map.flyTo(
				[
					Number((39.948039969988535).toFixed(4)),
					Number((-75.14274443448495).toFixed(4)),
				],
				map.getZoom()
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);
	return position === null ? null : (
		<Marker position={position}>
			<Popup>Eatwell-Philly Resturant is located here</Popup>
		</Marker>
	);
};

export default LocationMarker;
