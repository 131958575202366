import React from "react";
import { MainLayout } from "../layouts";
import {
  Box,
  Container,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from "@chakra-ui/react";

export const TermsOfService = () => {
  return (
    <MainLayout>
      <Box pt={["8rem", "15rem"]} pb="8rem">
        <Container maxW="container.lg">
          <Heading fontSize={["5xl", "7xl"]} fontWeight={900} textAlign="center">
            Terms of Service
          </Heading>

          <Text mt="2rem" fontSize={["lg", "xl"]}  textAlign="left">
            Last updated: Aug 11, 2023
          </Text>

          <VStack mt="2rem" spacing={8} justifyContent="flex-start">
            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Welcome to EatWell Philly!
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={8} textAlign="left">
                These terms and conditions ("Terms") govern your use of the
                website http://eatwellphilly.com (the "Website"). Please read
                these Terms carefully before using our services. By accessing or
                using the Website, you agree to be bound by these Terms. If you
                do not agree with any part of these Terms, you may not access or
                use the Website.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Acceptance of Terms
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                By using the Website, you acknowledge and agree to these Terms
                and any future updates or modifications to the Terms. You must
                be at least 18 years old to use the Website. If you are using
                the Website on behalf of an organization, you represent and
                warrant that you have the authority to bind that organization to
                these Terms.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Use of the Website
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                The Website is provided for informational purposes only and is
                intended for personal, non-commercial use. You may not use the
                Website in any way that is unlawful or violates these Terms. You
                agree not to:
              </Text>

              <OrderedList type="a" spacing={3} mt={4}>
                <ListItem fontSize={["lg", "xl"]} >
                  Access, tamper with, or use non-public areas of the Website or
                  our systems.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  Attempt to probe, scan, or test the vulnerability of any
                  system or network or breach any security or authentication
                  measures.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  Interfere with, disrupt, or attempt to gain unauthorized
                  access to other users' accounts or computer systems.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  Upload or transmit any harmful or malicious code, viruses, or
                  similar technologies that may damage or interfere with the
                  operation of the Website or any related system or network.
                </ListItem>
              </OrderedList>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Intellectual Property
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                The content, trademarks, logos, and other intellectual property
                on the Website are owned by Eat Well Philly and are protected by
                applicable copyright and trademark laws. You may not use,
                reproduce, distribute, or modify any content from the Website
                without our prior written consent.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Links to Third-Party Websites
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                The Website may contain links to third-party websites that are
                not owned or controlled by EatWell Philly. We do not endorse,
                monitor, or have any control over these third-party websites,
                and we are not responsible for their content, privacy policies,
                or practices. Your use of third-party websites is at your own
                risk, and you should review the terms and conditions and privacy
                policies of those websites.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Limitation of Liability
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                To the maximum extent permitted by law, EatWell Philly and its
                affiliates, officers, directors, employees, and agents shall not
                be liable for any direct, indirect, incidental, consequential,
                or special damages arising out of or in connection with your use
                of the Website or these Terms.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Indemnification
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                You agree to indemnify and hold EatWell Philly, its affiliates,
                officers, directors, employees, and agents harmless from any
                claims, losses, damages, liabilities, costs, and expenses
                (including reasonable attorneys' fees) arising out of or in
                connection with your use of the Website or any violation of
                these Terms.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Termination
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                Eat Well Philly may, in its sole discretion, terminate or
                suspend your access to the Website at any time and for any
                reason, including for violations of these Terms. Upon
                termination, your right to use the Website will immediately
                cease.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Changes to Terms
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We may update or modify these Terms at any time without prior
                notice. The updated Terms will be effective upon posting on the
                Website. Your continued use of the Website after any changes to
                these Terms will constitute your acceptance of the updated
                Terms.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Governing Law
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                These Terms shall be governed by and construed in accordance
                with the laws of Philadelphia, without regard to its conflicts
                of law principles.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Contact Us
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                If you have any questions or concerns about these Terms or the
                Website, please contact us at{" "}
                <Link href="mailto: food@eatwellphilly.com">
                  food@eatwellphilly.com
                </Link>
                .
              </Text>
            </Box>

            <Box w="full">
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                By using the Website, you acknowledge that you have read,
                understood, and agree to be bound by these Terms. Thank you for
                visiting Eat Well Philly! Enjoy your experience on our Website!
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </MainLayout>
  );
};
