import { Box, HStack, Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

interface TitleProps extends HeadingProps {
    text: string
}

export const Title = (props: TitleProps) => {
    const { text, ...restProps } = props
  return (
    <HStack spacing={["0.875rem", "1.5rem"]} alignItems="center">
        <Box display={["none", "block"]} width="1rem" h="1rem" rounded="full" borderWidth="4px" borderColor="secondary.600" />
        <Heading fontWeight="black" color="text.black" fontSize={["4xl", "6xl"]} {...restProps}>
            {text}
        </Heading>
    </HStack>
  )
}
