import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Icon,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { Title } from "../../components";
import SwiperCore, { Navigation, A11y, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.css";
// import "swiper/components/navigation/navigation.css";
import { SignatureCard } from "./SignatureCard";

import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import StartersImg from "../../assets/images/starters.jpg";
import AuthenticImg from "../../assets/images/authentic.jpg";
import PopularMainCourseImg from "../../assets/images/popular-main-courses.jpg";
import ProteinSidesImg from "../../assets/images/protein-sides.jpg";
import SidesImg from "../../assets/images/sides.jpg";
import SignatureImg from "../../assets/images/drink.jpg";

SwiperCore.use([Navigation, A11y, EffectFade]);

export interface SignatureDataProps {
  title: string;
  imgUrl: string;
}

const signatureData: SignatureDataProps[] = [
  {
    title: "Starters",
    imgUrl: StartersImg,
  },
  {
    title: "Authentic African Snacks",
    imgUrl: AuthenticImg,
  },
  {
    title: "Popular Main Courses",
    imgUrl: PopularMainCourseImg,
  },
  {
    title: "Protein Sides",
    imgUrl: ProteinSidesImg,
  },
  {
    title: "Sides",
    imgUrl: SidesImg,
  },
  {
    title: "Drinks",
    imgUrl: SignatureImg,
  },
];

export const Signature = () => {
  const isDesktop = useMediaQuery("(min-width: 2000px)")[0];
  return (
    <Box bg="border.largeFooter" py={["2.5rem", "5rem"]}>
      <Container maxW="container.xl">
        <Title text="The Eatwell Signature" />
      </Container>

      <Container
        maxW={[
          "container.xl",
          "container.xl",
          "container.xl",
          "container.xl",
          "container.xl",
          "2000px",
        ]}
      >
        <Box mt="2.5rem" display={["none", "block"]}>
          <Swiper
            spaceBetween={24}
            slidesPerView={isDesktop ? 5 : 3.2}
            navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
          >
            {signatureData.map((data) => (
              <SwiperSlide key={data.title}>
                <SignatureCard title={data.title} imgUrl={data.imgUrl} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <VStack
          spacing="1.25rem"
          mt={["1.5rem", "2.5rem"]}
          display={["flex", "none"]}
        >
          {signatureData.map((data) => (
            <SignatureCard title={data.title} imgUrl={data.imgUrl} />
          ))}
        </VStack>
      </Container>

      <Container maxW="container.xl">
        <HStack justifyContent={["flex-start", "space-between"]} mt="2.5rem">
          <Text fontSize={["lg", "xl"]} fontWeight="light" color="black">
            We are dedicated to gracing your tastebuds with the best meals
          </Text>

          <HStack display={["none", "flex"]} spacing="1.5rem">
            <Center
              className="arrow-right"
              cursor="pointer"
              as={Button}
              bg="navigationArrowBg"
              w="3.125rem"
              h="3.125rem"
              rounded="full"
            >
              <Icon as={IoChevronBackOutline} fontSize="2xl" />
            </Center>
            <Center
              className="arrow-left"
              cursor="pointer"
              as={Button}
              bg="navigationArrowBg"
              w="3.125rem"
              h="3.125rem"
              rounded="full"
            >
              <Icon as={IoChevronForwardOutline} fontSize="2xl" />
            </Center>
          </HStack>
        </HStack>
      </Container>
    </Box>
  );
};
