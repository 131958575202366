import {
  api as httpApi,
  buildQueryString as buildQueryStringUtil,
} from "../utils";

export class ModelService<Model = unknown> {
  protected modelName = "";
  protected api = httpApi;
  protected buildQueryString = buildQueryStringUtil;

  constructor(modelName: string) {
    this.modelName = modelName;
  }

  read = (_id?: string) => {
    return this.api
      .get<{ data: Model }>(`/${this.modelName}/${_id}`)
      .then(({ data }) => data.data);
  };

  readAll = (payload: {
    pageSize?: number;
    pageNumber?: number;
    filter?: {
      [key: string]: string | number | boolean | null | undefined;
    };
  }) => {
    const queryString = this.buildQueryString(payload as Record<never, never>);
    return this.api
      .get<{ data: Model[] }>(`/${this.modelName}${queryString}`)
      .then(({ data }) => data.data);
  };

  readManyBy = (payload: Partial<Model>) => {
    const queryString = this.buildQueryString(payload as Record<never, never>);
    return this.api
      .get<{ data: Model[] }>(`/${this.modelName}/read_many_by${queryString}`)
      .then(({ data }) => data.data);
  };

  create = (payload: Partial<Model>) => {
    return this.api
      .post<{ status: string; message: string; data: Model }>(
        `/${this.modelName}`,
        payload
      )
      .then(({ data }) => data);
  };

  update = (payload: Partial<Model>) => {
    const { id } = payload as never;
    return this.api
      .patch<{ status: string; message: string }>(
        `/${this.modelName}/${id}`,
        payload
      )
      .then(({ data }) => data);
  };

  delete = (payload: Partial<Record<never, never>>) => {
    const { id } = payload as never;
    const queryString = this.buildQueryString(payload);
    return this.api.delete(`/${this.modelName}/${id}${queryString}`);
  };
}
