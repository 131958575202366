import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { MenuCard } from "./MenuCard";
import { Meal } from "../model";

interface MenuGridProps {
  menu: Meal[];
}

export const MenuGrid = (props: MenuGridProps) => {
  const { menu } = props;
  
  return (
    <SimpleGrid columns={[1, 2]} spacing="1.25rem">
      {menu && menu.length
        ? menu.map((item) => (
            <MenuCard
              key={item.productname}
              {...item}
            />
          ))
        : null}
    </SimpleGrid>
  );
};
