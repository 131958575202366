import { Tag, Text } from "@chakra-ui/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

interface FilterItemProps {
  isActive?: boolean;
  text?: string;
  onClick?: () => void;
  setPage?: (page: number) => void;
}

const FilterItem = (props: FilterItemProps) => {
  const { isActive, text, onClick, setPage } = props;

  const [, setSearchParams] = useSearchParams();

  return (
    <Tag
      rounded="2rem"
      px={["1.5rem", "2.5rem"]}
      py={["0.75rem", "1.25rem"]}
      onClick={() => {
        setSearchParams({ filter: text === "Soups (No Meat Included)" ? "Soups" : text || "" });
        setPage && setPage(1);
        onClick && onClick();
      }}
      fontSize={["md", "xl"]}
      bg={isActive ? "filterActiveBg" : "filterBg"}
      color={isActive ? "white" : "gray.600"}
      _hover={{ bg: "filterActiveBg", color: "gray.600" }}
      cursor="pointer"
    >
      <Text>
        {text || ""}
      </Text>
    </Tag>
  );
};

export default FilterItem;
