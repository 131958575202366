export const formatCurrency = (
    amount: number,
    settings?: { decimals?: number; showCurrency?: boolean; compact?: boolean }
  ): any => {
    if (isNaN(amount)) return false;
  
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: settings?.decimals ?? 2,
      maximumFractionDigits: settings?.decimals ?? 2,
    };
  
    if (settings?.compact) {
      options.notation = "compact";
      options.compactDisplay = "short";
      options.minimumFractionDigits = 0;
    }
  
    if (settings?.showCurrency) {
      options.style = "currency";
      options.currency = "USD";
      options.currencyDisplay = "symbol";
    }
  
    return new Intl.NumberFormat("en-US", options).format(amount);
  };
  