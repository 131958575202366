import React, { PropsWithChildren } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2
    }
  }
})

interface HttpProviderProps extends PropsWithChildren {}

export const HttpProvider = ({ children }: HttpProviderProps) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)
