import {
  Box,
  Container,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React from "react";

import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";

import Logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

export const Header = () => {
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <Box
      bg="header.bg"
      backdropFilter="auto"
      backdropBlur="5px"
      py={["0.875rem", "1.375rem"]}
      pos="fixed"
      top="0"
      left="0"
      right="0"
      zIndex={1401}
    >
      <Container
        maxW={[
          "container.xl",
          "container.xl",
          "container.xl",
          "container.lg",
          "container.xl",
        ]}
      >
        <HStack justifyContent="space-between" alignItems="center">
          <HStack spacing="1.5rem">
            <Icon
              as={show ? IoClose : BiMenu}
              fontSize="2.2rem"
              color="white"
              cursor="pointer"
              onClick={() => setShow(!show)}
            />
            <Link to="/">
              <Image
                src={Logo}
                alt="philly eatery logo"
                w={["4.3rem", "5.625rem"]}
                h="auto"
              />
            </Link>
          </HStack>

          <HStack
            maxW="150px"
            rounded="28px"
            px={["1rem", "1.5rem"]}
            py={["0.75rem", "1rem"]}
            bg="header.grayBg"
            cursor="pointer"
            as={ChakraLink}
            href="tel: +1 215-964-9213"
          >
            <Text whiteSpace="nowrap" fontSize={["lg", "xl"]} color="white">
              Contact us
            </Text>
            <Icon as={FiPhoneCall} fontSize="1rem" color="white" />
          </HStack>
        </HStack>

        {show ? (
          <VStack pl={["3.8rem"]}>
            <Text
              w="full"
              py="0.5rem"
              fontSize={["md", "xl"]}
              as={Link}
              cursor="pointer"
              color="white"
              to="/menu"
            >
              Menu
            </Text>
            <Text
              w="full"
              py="0.5rem"
              fontSize={["md", "xl"]}
              as={Link}
              to="/events"
              cursor="pointer"
              color="white"
            >
              Catering & Events
            </Text>
            <Text
              w="full"
              py="0.5rem"
              fontSize={["md", "xl"]}
              as={Link}
              cursor="pointer"
              color="white"
              to="/career"
            >
              Career
            </Text>
          </VStack>
        ) : null}
      </Container>
    </Box>
  );
};
