import React from 'react'
import { MainLayout } from '../layouts'
import { CareerHero } from '../features/career/CareerHero'
import { ApplicationForm } from '../features/career/ApplicationForm'

export const Career = () => {
  return (
    <MainLayout>
        <CareerHero />
        <ApplicationForm />
    </MainLayout>
  )
}
