import { ModelService } from "./base";

export class ReservationService extends ModelService<any> {
  constructor() {
    super("reservation");
  }

  createReservation = (payload: {
    name: string;
    email: string;
    phone: string;
    reservationdate: string;
    reservationtime: string;
    requested_table_count: number;
    note?: string;
  }) => {
    return this.api
      .post<{ data: any }>(`/${this.modelName}`, payload)
      .then(({ data }) => data);
  };
}

export const reservationService = new ReservationService();
