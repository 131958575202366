import { Box, Image } from "@chakra-ui/react";
import React from "react";

interface SignatureCardProps {
  title: string;
  imgUrl: string;
}

export const SignatureCard = (props: SignatureCardProps) => {
  const { imgUrl } = props;

  return (
    <Box w="full">
      <Box
        w="full"
        maxW={["full", "full", "full", "400px"]}
        bg="white"
        rounded="10px"
        overflow="hidden"
        borderWidth="1px"
        borderColor="border.card"
        boxShadow="md"
        p="10px"
        pb="50px"
      >
        <Image rounded="10px" src={imgUrl} w="full" h={["288px", "320px"]} objectFit="cover" />
      </Box>
    </Box>
  );
};
