import React from 'react'
import { LayoutWithHead } from '../layouts'
import { Heading } from '@chakra-ui/react'
import { ReservationForm } from '../features/reservations'

export const Reservations = () => {
  return (
    <LayoutWithHead title="Reservations">
        <Heading mb="2.5rem" fontSize="4xl" color="border.largeFooter">Reservation Details</Heading>

        <ReservationForm />
    </LayoutWithHead>
  )
}
