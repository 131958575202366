import {
  Box,
  Button,
  Container,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import AboutLogo from "../../assets/images/about-logo.svg";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export const About = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const handleVideoEnd = () => {
      setIsPlaying(false);
    };

    if(!videoRef.current) return;
    const videoEl = videoRef.current;
    videoEl.addEventListener('ended', handleVideoEnd);

    // Remember to remove the event listener when the component unmounts
    return () => {
      videoEl.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  return (
    <Box h={["418px", "100vh"]} pos="relative">
      {/* <Image
        src={AboutBg}
        pos="absolute"
        loading="lazy"
        top="0"
        left="0"
        w="full"
        h="full"
        alt="About"
      /> */}

      <video
        ref={videoRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        autoPlay={false}
        muted
      >
        <source
          src="https://eatwell-website-images.s3.eu-central-1.amazonaws.com/Eatwell++Restuarant+Video.mp4"
          type="video/mp4"
        />
      </video>

      <Box
        pos="absolute"
        top="0"
        left="0"
        w="full"
        h="full"
        bg="rgba(33, 58, 73, 0.65)"
        zIndex={1}
      />

      <Container
        h="full"
        maxW="container.xl"
        pos="relative"
        zIndex={1}
        py={["1.5rem", "4rem"]}
      >
        <VStack h="full" justifyContent="space-between" alignItems="center">
          <Box />
          {!isPlaying ? (
            <Image
              cursor="pointer"
              onClick={playVideo}
              src={AboutLogo}
              w={["6.25rem", "6.5rem"]}
              h={["6.25rem", "6.5rem"]}
            />
          ) : null}

          <VStack spacing={["1rem", "1.5rem"]} alignItems="center" maxW="521px">
            <Text
              textAlign="center"
              fontSize={["md", "xl"]}
              fontWeight={300}
              color="text.bodyGray"
            >
              Our dishes are made with love and served with royalty
            </Text>

            <HStack
              flexDir={["column", "row", "row"]}
              mt={["0", "2.5rem"]}
              spacing={["0.5rem", "1.25rem"]}
            >
              <Button
                bg="secondary.600"
                _hover={{ bg: "secondary.700" }}
                color="white"
                fontSize={["md", "1.25rem"]}
                p={["1.25rem", "2rem"]}
                py={["1.75rem", "2rem"]}
                rightIcon={<Icon as={BsArrowUpRight} />}
                as={Link}
                to="/menu"
              >
                See Our Menu
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};
