import {
  Box,
  HStack,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

import FoodImage from "../../assets/images/menu.jpg";
import { useAtom } from "jotai";
import { formatCurrency } from "../../utils";
import { mealAtom } from "../../store/meal";
import { BiArrowBack } from "react-icons/bi";

export const SelectedMealModal = () => {
  const [meal, setMeal] = useAtom(mealAtom);
  function onClose() {
    // setQuantity(1);
    // setTopping("");
    // setProductoptions("");
    setMeal(null);
  }

  // const [quantity, setQuantity] = React.useState<number>(1);
  // const [topping, setTopping] = React.useState<string>("");
  // const [productoptions, setProductoptions] = React.useState<string>("");
  // const [note, setNote] = React.useState<string>("");

  // const selectedTopping = React.useMemo(() => {
  //   if(!meal?.topping?.allFish) return null;
  //   // convert meal.topping map to array using the value
  //   const toppingArray = meal?.topping?.allFish ? Object.values(meal?.topping) : [];

  //   // find the topping that matches the topping id
  //   const newSelectedTopping = toppingArray.find(
  //     (topp) => topp.id === topping
  //     )

  //   return newSelectedTopping;

  // }, [meal?.topping, topping]);

  // const selectedProductOption = React.useMemo(() => {
  //   if(!meal?.productoptions) return null;
  //   const newSelectedProductOption = meal?.productoptions.find(
  //     (productOption) => productOption.id === productoptions
  //   )

  //   if(!newSelectedProductOption) return null;

  //   return newSelectedProductOption;

  // }, [meal?.productoptions, productoptions]);

  // const totalPrice = React.useMemo(() => {
  //   let total = 0;

  //   if (meal) {
  //     total += Number(meal.price) * quantity;
  //   }

  //   if (selectedTopping) {
  //     total += Number(selectedTopping.price) * quantity;
  //   }

  //   if (selectedProductOption) {
  //     total += Number(selectedProductOption.Price) * quantity;
  //   }

  //   return total;
  // }, [meal, selectedTopping, selectedProductOption, quantity]);

  // const handleAddToTray = () => {
  //   const newMealTray = {
  //     price: meal?.price || '0',
  //     id: meal?.id || '',
  //     productname: meal?.productname || '',
  //     quantity,
  //     topping: selectedTopping ? [selectedTopping] : [],
  //     productoptions: selectedProductOption ? [selectedProductOption] : [],
  //     note: "",
  //   };

  //   if(mealTray?.length === 0) {
  //     if(note !== "") {
  //       newMealTray.note = note;
  //     }
  //     setMealTray([newMealTray]);
  //     sessionStorage.setItem("mealTray", JSON.stringify([newMealTray]));
  //   }
  //   else {
  //     // check if meal is already in tray
  //     const mealInTray = mealTray.find((meal) => meal.id === newMealTray.id);
  //     const otherMeals = mealTray.filter((meal) => meal.id !== newMealTray.id);
  //     // if meal is in tray, update the quantity
  //     if(mealInTray) {
  //       mealInTray.quantity += newMealTray.quantity;
  //       if(note !== "") {
  //         mealInTray.note = note;
  //       }
  //       setMealTray([...otherMeals, mealInTray]);
  //       sessionStorage.setItem("mealTray", JSON.stringify([...otherMeals, mealInTray]));
  //     }
  //     else {
  //       if(note !== "") {
  //         newMealTray.note = note;
  //       }
  //       setMealTray([...mealTray, newMealTray]);
  //       sessionStorage.setItem("mealTray", JSON.stringify([...mealTray, newMealTray]));
  //     }
  //   }

  //   onClose();
  // };

  return (
    <Modal isOpen={meal !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        pos="absolute"
        top="4rem"
        right="0"
        rounded={0}
        maxW="500px"
        // w="full"
        roundedLeft={["0", "10px"]}
        px={["0.44rem", 0]}
        py="0"
        overflow="hidden"
        w={["95%", "full"]}
      >
        <Box
          onClick={onClose}
          alignItems="center"
          mt="1.25rem"
          mb="0.65rem"
          px="1rem"
          py="0.75rem"
          maxW="6.125rem"
          rounded="full"
          bg="rgba(2, 48, 71, 0.50)"
          display={["flex", "none"]}
        >
          <Icon as={BiArrowBack} color="white" fontSize="1.5rem" />
          <Text ml="0.5rem" fontSize="md" fontWeight={500} color="white">
            Back
          </Text>
        </Box>
        <ModalCloseButton
          left="0.5rem"
          top="0.5rem"
          w="2.5rem"
          h="2.5rem"
          bg="white"
          pos={["static", "absolute"]}
          rounded="full"
          display={["none", "flex"]}
        />
        <ModalBody px="0" py="0">
          <Image
            src={meal?.imageurl !== "" ? meal?.imageurl : FoodImage}
            w="full"
            h="300px"
            objectFit="cover"
            rounded={["10px", "0"]}
          />

          <Box w="full" px={["1.25rem", "2.5rem"]} py="2rem">
            <HStack
              w="full"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Text w="full" fontSize="xl" fontWeight={500} color="primary.700">
                {meal?.productname}
              </Text>
              <Text
                w="full"
                fontSize="lg"
                fontWeight={500}
                color="text.bodyDarkGray"
                textAlign="right"
              >
                {formatCurrency(Number(meal?.price) / 100, {
                  showCurrency: true,
                })}
              </Text>

              {/* <HStack spacing="1rem">
                <Center
                  cursor="pointer"
                  _hover={{ bg: "gray.300" }}
                  w={["2.5rem", "3rem"]}
                  h={["2.5rem", "3rem"]}
                  rounded="full"
                  bg="gray.200"
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                    }
                  }}
                >
                  <Icon as={HiMinus} fontSize="1rem" />
                </Center>
                <Text fontSize="2xl" fontWeight={700} color="primary.700">
                  {quantity}
                </Text>
                <Center
                  cursor="pointer"
                  _hover={{ bg: "gray.300" }}
                  w={["2.5rem", "3rem"]}
                  h={["2.5rem", "3rem"]}
                  rounded="full"
                  bg="gray.200"
                  onClick={() => {
                    setQuantity(quantity + 1);
                  }}
                >
                  <Icon as={HiPlus} fontSize="1rem" />
                </Center>
              </HStack> */}
            </HStack>

            {meal?.description !== "" ? (
              <Box
                p="1.25rem"
                rounded="10px"
                borderWidth={1}
                borderColor="rgba(218, 218, 218, 1)"
                bg="rgba(255, 252, 248, 1)"
                mt={8}
              >
                <Heading fontSize="xl">Description</Heading>
                <Text
                  mt={4}
                  fontSize="md"
                  fontWeight={400}
                  color="rgba(83, 83, 83, 1)"
                >
                  {meal?.description}
                </Text>
              </Box>
            ) : null}

            {/* radio group */}
            {/* <HStack spacing="8" mt="6">
              {meal?.productoptions?.length ? (
                <VStack alignItems="flex-start">
                  <Text
                    fontSize="md"
                    fontWeight={500}
                    color="text.bodyDarkGray"
                  >
                    Select Swallow
                  </Text>
                  <RadioGroup value={productoptions} onChange={setProductoptions}>
                    <Stack direction="column">
                      {meal?.productoptions?.map((option) => (
                        <Radio
                          key={option.id}
                          size="lg"
                          fontWeight={400}
                          value={option.id}
                        >
                          {option.ProductOptionName}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </VStack>
              ) : null}

              {meal?.topping?.allFish ? (
                <VStack alignItems="flex-start">
                  <Text
                    fontSize="md"
                    fontWeight={500}
                    color="text.bodyDarkGray"
                  >
                    Select Protein Side
                  </Text>
                  <RadioGroup value={topping} onChange={setTopping}>
                    <Stack direction="column">
                      <Radio
                        size="lg"
                        fontWeight={400}
                        value={meal?.topping?.allFish.id}
                      >
                        {meal?.topping?.allFish.name}
                      </Radio>
                      <Radio size="lg" fontWeight={400} value={meal?.topping?.allMeat?.id}>
                        {meal?.topping?.allMeat?.name}
                      </Radio>
                      <Radio size="lg" fontWeight={400} value={meal?.topping?.assorted?.id}>
                        {meal?.topping?.assorted?.name}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </VStack>
              ) : null}
            </HStack>
            
            <FormControl mt="1rem">
              <FormLabel color="text.bodyDarkGray">Note (Optional)</FormLabel>
              <Textarea value={note} onChange={(e) => setNote(e.target.value)} />
            </FormControl>

            <Box mt="6">
              <RightElement
                height="4.5rem"
                title="Add to Food Tray"
                isCount={false}
                onClick={handleAddToTray}
                price={formatCurrency(Number(totalPrice) / 100, {
                  showCurrency: true,
                })}
                isLight
              />
            </Box> */}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
