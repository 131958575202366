import { Box, SimpleGrid, SkeletonText } from "@chakra-ui/react";
import React from "react";

export const MealLoading = () => {
  return (
    <SimpleGrid columns={[1, 3]} spacing="1.25rem">
      <MealCardLoading />
      <MealCardLoading />
      <MealCardLoading />
      <MealCardLoading />
      <MealCardLoading />
    </SimpleGrid>
  );
};

const MealCardLoading = () => {
  return (
    <Box
      padding="6"
      borderWidth="0.5px"
      borderColor="rgba(255, 248, 240, 0.25)"
      boxShadow="0px 10px 10px -10px rgba(0, 0, 0, 0.03)"
      rounded="8px"
      bg="filterBg"
    >
      <SkeletonText mt="4" noOfLines={2} spacing="4" />
    </Box>
  );
};
