import { Button, Image } from "@chakra-ui/react";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

import CalenderEdit from "../../assets/images/calender-edit.svg";
import Clock from "../../assets/images/clock.svg";

interface CustomDateInputProps {
  value?: string;
  onClick?: () => void;
  isTime?: boolean;
}

const CustomDateInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomDateInputProps
> = ({ value, onClick, isTime }, ref) => (
  <Button
    rounded="5px"
    borderColor="border.darkInput"
    borderWidth="0.5px"
    fontSize="xl"
    fontWeight={300}
    h="5rem"
    w="full"
    color="border.largeFooter"
    _placeholder={{
      color: "gray.700",
    }}
    _hover={{
        bg: "filterBg",
    }}
    bg="filterBg"
    onClick={onClick}
    ref={ref}
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    px="2rem"
  >
    {value}

    <Image 
        src={isTime ? Clock : CalenderEdit}
        alt="calender"
        w="1.25rem"
        h="1.25rem"
    />
  </Button>
);

export default forwardRef(CustomDateInput);
