import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Career, CareerSuccess, Events, Home, Menu } from "./pages";
import { PrivacyAndPolicy } from "./pages/PrivacyAndPolicy";
import { TermsOfService } from "./pages/TermsOfService";
import { Reservations } from "./pages/Reservations";
// import { Payment } from "./pages/Payment";
// import { PaymentSuccess } from "./pages/PaymentSuccess";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/menu" element={<Menu />}></Route>
        <Route path="/career" element={<Career />}></Route>
        <Route path="/career/success" element={<CareerSuccess />}></Route>
        <Route path="/events" element={<Events />}></Route>
        <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />}></Route>
        <Route path="/terms-of-service" element={<TermsOfService />}></Route>
        <Route path="/reservations" element={<Reservations />}></Route>
        {/* <Route path="/take-out" element={<TakeOut />}></Route> */}
        {/* <Route path="/take-out/checkout" element={<Checkout />}></Route> */}
        {/* <Route path="/take-out/payment" element={<Payment />}></Route> */}
        {/* <Route path="/take-out/payment/success" element={<PaymentSuccess />}></Route> */}
      </Routes>
    </Router>
  );
}

export default App;
