import React from "react";
import { MainLayout } from "../layouts";
import {
  Box,
  Container,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from "@chakra-ui/react";

export const PrivacyAndPolicy = () => {
  return (
    <MainLayout>
      <Box pt={["8rem", "15rem"]} pb="8rem">
        <Container maxW="container.lg">
          <Heading fontSize={["5xl", "7xl"]} fontWeight={900} textAlign="center">
            Privacy Policy
          </Heading>

          <Text mt="2rem" fontSize={["lg", "xl"]} textAlign="left">
            Last updated: 11th August, 2023
          </Text>

          <VStack mt="2rem" spacing={8} justifyContent="flex-start">
            <Text fontSize={["lg", "xl"]}  textAlign="left">
              At EatWell Philly, we respect your privacy and are committed to
              protecting your personal information. This Privacy Policy outlines
              how we collect, use, and safeguard your personal data when you use
              our website www.eatwellphilly.com (the "Website").
            </Text>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Information We Collect
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                When you access or use the Website, we may collect certain
                personal information from you. The types of information we may
                collect include:
              </Text>

              <OrderedList type="a" spacing={3} mt={4}>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Contact Information:
                  </Text>{" "}
                  Your name, email address, phone number, and any other
                  information you provide when contacting us or subscribing to
                  our newsletter.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Log Data:
                  </Text>{" "}
                  Information about your use of the Website, including your IP
                  address, browser type, operating system, and pages visited.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Cookies and Similar Technologies:
                  </Text>{" "}
                  We use cookies and similar technologies to collect information
                  about your preferences, settings, and browsing patterns on the
                  Website.
                </ListItem>
              </OrderedList>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                How We Use Your Information
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We may use the information we collect for various purposes,
                including:
              </Text>

              <OrderedList type="a" spacing={3} mt={4}>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Providing and Improving the Website:
                  </Text>{" "}
                  To operate, maintain, and improve the functionality of the
                  Website and enhance your user experience.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Communication:
                  </Text>{" "}
                  To respond to your inquiries, provide customer support, and
                  send you updates or promotional materials related to our
                  services.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Analytics:
                  </Text>{" "}
                  To analyze usage patterns, monitor the performance of the
                  Website, and gather demographic information about our users.
                </ListItem>
                <ListItem fontSize={["lg", "xl"]} >
                  <Text as="span" fontWeight={600}>
                    Compliance:
                  </Text>{" "}
                  To comply with applicable laws, regulations, and legal
                  processes.
                </ListItem>
              </OrderedList>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Cookies and Tracking Technologies
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We use cookies and similar tracking technologies to collect and
                store certain information about your interactions with the
                Website. Cookies are small data files stored on your device that
                enable us to recognize your browser and capture certain
                information. You can control the use of cookies through your
                browser settings, but please note that disabling cookies may
                limit some features of the Website.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Third-Party Services
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We may use third-party services, such as analytics providers or
                advertising partners, that collect and process information on
                our behalf. These third-party services may have their own
                privacy policies, and we encourage you to review their policies
                to understand how they handle your data.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Data Security
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We take appropriate measures to protect your personal
                information from unauthorized access, disclosure, alteration, or
                destruction. However, please be aware that no method of
                transmission over the internet or electronic storage is entirely
                secure, and we cannot guarantee the absolute security of your
                data.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Data Retention
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We will retain your personal information for as long as
                necessary to fulfill the purposes outlined in this Privacy
                Policy, unless a longer retention period is required or
                permitted by law.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Your Rights
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                You have the right to access, update, correct, or delete your
                personal information. If you wish to exercise any of these
                rights, please contact us at the email address provided below.
                Please note that we may need to retain certain information for
                legitimate business purposes or as required by law.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Children's Privacy
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                The Website is not intended for use by children under the age of
                18. We do not knowingly collect personal information from
                children. If you are a parent or guardian and believe that your
                child has provided us with personal information, please contact
                us at the email address below, and we will promptly delete such
                information.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Changes to this Privacy Policy
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. Any changes will be effective immediately
                upon posting the revised Privacy Policy on the Website.
              </Text>
            </Box>

            <Box w="full">
              <Heading fontSize="3xl" fontWeight={900} textAlign="left">
                Contact Us
              </Heading>
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                If you have any questions, concerns, or requests related to this
                Privacy Policy or your personal information, please contact us
                at{" "}
                <Link href="mailto: food@eatwellphilly.com">
                  food@eatwellphilly.com
                </Link>
                .
              </Text>
            </Box>

            <Box w="full">
              <Text fontSize={["lg", "xl"]}  mt={2} textAlign="left">
                By using the Website (www.eatwellphilly.com), you consent to the
                collection and use of your personal information as described in
                this Privacy Policy. Thank you for trusting EatWell Philly with
                your data and for visiting our Website! Enjoy your experience!
              </Text>
            </Box>
          </VStack>
        </Container>
      </Box>
    </MainLayout>
  );
};
