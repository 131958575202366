import { Box } from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect } from 'react'
import { Header } from './Header';
import { Footer } from './Footer';

interface MainLayoutProps extends PropsWithChildren {

}

export const MainLayout = (props: MainLayoutProps) => {
    const { children } = props;
    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (
    <Box scrollBehavior="smooth">
        <Header />
        <Box>
            {children}
        </Box>
        <Footer />
    </Box>
  )
}
