import {
  Box,
  Button,
  Container,
  Icon,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Title } from "../../components";

import { MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { BsArrowUpRight } from "react-icons/bs";
import { MdOutlineMyLocation } from "react-icons/md";
import LocationMarker from "./LocationMarker";

export const LocateUs = () => {
  const [isLessThan760] = useMediaQuery("(max-width: 760px)");

  const mapHeight = useMemo(() => {
    if (isLessThan760) {
      return "400px";
    }
    return "500px";
  }, [isLessThan760]);

  const [isLocated, setIsLocated] = useState(false);

  const locateLocation = () => {
		setIsLocated(true);
	};

  return (
    <Box w="full" bg="border.largeFooter">
      <Container maxW="container.xl" pt={["2rem", "5rem"]} pb={["2rem", "4rem"]}>
        <Title text="Find Your Way to Eatwell Philly" />
        <Box
          boxShadow="md"
          w="full"
          rounded="10px"
          mt="2rem"
          overflow="hidden"
          pos="relative"
        >
          <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: mapHeight, width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <LocationMarker value={isLocated} />
          </MapContainer>

{/* cehck */}
          <Button
            px="0.75rem"
            py="0.875rem"
            bg="border.largeFooter"
            _hover={{ bg: "border.largeFooter" }}
            pos="absolute"
            top="0.75rem"
            left={["3rem", "3.5rem"]}
            zIndex={777}
            rounded="4px"
            onClick={() => locateLocation()}
          >
            <Icon fontSize={["0.875rem", "1.25rem"]} as={MdOutlineMyLocation} color="primary.500" />
            <Text color="primary.500" fontSize={["0.75rem", "1.25rem"]} fontWeight={400} ml={["0.5rem", "0.75rem"]}>
              106 Chestnut Street, Philadelphia, PA 19106
            </Text>
          </Button>

          <Button
            pos="absolute"
            bottom="1.5rem"
            left="1.5rem"
            zIndex={777}
            bg="mapButtonBg"
            _hover={{ bg: "secondary.200", color: "secondary.700" }}
            color="white"
            fontSize={["md", "1.25rem"]}
            p={["1.25rem", "2rem"]}
            py={["1.75rem", "2rem"]}
            rightIcon={<Icon as={BsArrowUpRight} />}
            onClick={() => locateLocation()}
          >
            Locate Us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
