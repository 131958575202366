import {
  Box,
  Button,
  Container,
  Flex,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Text,
  keyframes,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React from "react";

import { BsArrowUpRight } from "react-icons/bs";

import HeroPattern from "../../assets/images/heroPattern.png";
import heroMainFood from "../../assets/images/heroMainFood.png";
import FirstSubFood from "../../assets/images/second-sub-food.png";
import SecondSubFood from "../../assets/images/first-sub-food.png";
import Fox291 from "../../assets/images/fox-291.svg";
import { Link } from "react-router-dom";

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;

export const Hero = () => {
  const spinAnimation = `${spin} infinite 8s linear`;
  return (
    <Box
      pt={["7rem", "8rem"]}
      bg="primary.500"
      pos="relative"
      overflow="hidden"
    >
      <Image
        src={HeroPattern}
        w="100vw"
        pos="absolute"
        top="0"
        left="0"
        h="100%"
        loading="lazy"
        objectFit={"cover"}
      />
      <Container maxW="container.xl" pos="relative" zIndex={1}>
        <SimpleGrid
          columns={[1, 1, 1, 1, 2]}
          gap={["12", "12"]}
          h={["auto", "auto", "auto", "auto", "calc(100vh - 9rem)"]}
          maxH={"750px"}
        >
          <GridItem h="full" as={Flex} flexDir="column" justifyContent="center">
            <Heading
              as="h1"
              fontSize={["2.15rem", "3.3rem"]}
              lineHeight={1.3}
              fontWeight={900}
              color="white"
              mb={["1rem", "1.5rem"]}
              textTransform="capitalize"
            >
              Experience the feeling of Africa’s best cuisine in Philly
            </Heading>
            <Text fontSize={["lg", "2xl"]} fontWeight={300} color="gray.50">
              At Eatwell Philly, we are dedicated to serving you the best
              African meals that makes you crave for more.
            </Text>

            <HStack alignItems="center" mt={["1rem", "2rem"]} spacing={["0.75rem", "1.25rem"]}>
              <Button
                bg="secondary.600"
                _hover={{ bg: "secondary.700" }}
                color="white"
                fontSize={["0.875rem", "1.25rem"]}
                p={["1.25rem", "2rem"]}
                py={["1.5rem", "2rem"]}
                rightIcon={<Icon as={BsArrowUpRight} />}
                as={Link}
                to="/menu"
              >
                See Our Menu
              </Button>
              {/* <Button
                bg="header.grayBg"
                _hover={{ bg: "secondary.200", color: "secondary.700" }}
                color="white"
                fontSize={["0.875rem", "1.25rem"]}
                p={["1.25rem", "2rem"]}
                py={["1.5rem", "2rem"]}
                rightIcon={<Icon as={BsArrowUpRight} />}
                as={ChakraLink}
                href="#services"
              >
                Explore Our Services
              </Button> */}
              <HStack
                as={ChakraLink}
                href="https://www.fox29.com/video/1255620"
                target="blank"
              >
                <Text color="white" fontSize={["sm", "md"]} fontWeight="400">
                  As Seen on TV
                </Text>
                <Image
                  src={Fox291}
                  alt="fox 291"
                  width={["5rem", "7.85rem"]}
                  height="auto"
                />
              </HStack>
            </HStack>
          </GridItem>

          <GridItem h={["18rem", "18rem", "25rem", "25rem", "full"]} pos="relative">
            <Box
              pos="absolute"
              top={["430px", "430px", "430px", "430px", "50%"]}
              left={["50%", "50%", "50%", "50%", "100%"]}
              transform="translate(-50%, -50%)"
              width={["500px", "500px", "500px", "500px", "650px"]}
              h={["500px", "500px", "500px", "500px", "650px"]}
              borderWidth="6px"
              borderColor="primary.600"
              rounded="full"
            />
            <Box
              pos="absolute"
              top={["7.5rem", "7.5rem", "7.5rem", "7.5rem", "50%"]}
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex={2}
              w={["14rem", "14rem", "20rem", "20rem", "25.25rem"]}
              h="auto"
            >
              <Image
                src={heroMainFood}
                alt="hero main food"
                animation={spinAnimation}
              />
            </Box>
            <Image
              src={FirstSubFood}
              alt="first sub food"
              width={["8rem", "12.5rem"]}
              pos="absolute"
              top={["13rem", "13rem", "16rem", "16rem", "13.5%"]}
              left={["18%", "18%", "24%", "32%", "63.5%"]}
              transform="translate(-50%, -50%)"
            />
            <Image
              src={SecondSubFood}
              alt="second sub food"
              width={["8rem", "12.5rem"]}
              pos="absolute"
              top={["9.5rem", "9.5rem", "9.5rem", "9.5rem", "70%"]}
              left={["64%", "64%", "62%", "58%", "48%"]}
              transform={["rotate(-15deg)", "rotate(-15deg)", "rotate(-30deg)", "rotate(-30deg)", "rotate(10deg)"]}
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </Box>
  );
};
