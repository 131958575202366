import { Box, HStack } from '@chakra-ui/react'
import React from 'react'
import FilterItem from './FilterItem'
import { useSearchParams } from 'react-router-dom';

interface FilterListsProps {
    filters: string[];
    setPage: (page: number) => void;
}

export const FilterLists = (props: FilterListsProps) => {
    const { filters, setPage } = props;
    const [searchParams] = useSearchParams();
    const filter = searchParams.get("filter") || "All";
  return (
    <Box w={["full"]} overflowX="scroll">
        <HStack w="max-content" spacing={["0.75rem", "1.5rem"]}>
            {
                filters ? filters.map((item) => (
                    <FilterItem setPage={setPage} text={item} isActive={item === filter} />
                )) : null
            }
        </HStack>
    </Box>
  )
}
