import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  Link as ChakraLink,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
  useToast,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import React from "react";

import { FaInstagram } from "react-icons/fa";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import Logo from "../assets/images/logo.svg";
import HeroPattern from "../assets/images/heroPattern.png";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { suggestionService } from "../services/suggestion";
import { useForm } from "react-hook-form";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";

const schema = Yup.object({
  fullname: Yup.string().min(2).max(30).required().label("Name"),
  email: Yup.string().min(3).max(35).required().email().label("Email"),
  message: Yup.string().min(5).max(600).required().label("Message"),
});

export type SuggestionFormInputType = Yup.InferType<typeof schema>;

export const Footer = () => {
  const toast = useToast();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      message: "",
      fullname: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(suggestionService.sendSuggestion, {
    onSuccess: (data: any) => {
      toast({
        title: "Message sent successfully",
        description: "We will get back to you as soon as possible",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      reset();
    },
    onError: (error: any) => {
      toast({
        title: "Error sending message",
        description: error?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = (data: SuggestionFormInputType) => {
    mutate(data);
  };

  return (
    <Box bg="primary.500" py="3.75rem" pos="relative">
      <Image
        src={HeroPattern}
        w="100vw"
        pos="absolute"
        top="0"
        left="0"
        h="100%"
        loading="lazy"
        objectFit={"cover"}
      />
      <Container pos="relative" zIndex={2} maxW="container.xl">
        <SimpleGrid columns={[1, 2]} spacing={4}>
          <GridItem maxW="646px" w="full">
            <Heading color="white" fontSize={["5xl", "7xl"]} mb="2rem">
              Eatwell Philly, The Food Hub of African Delicacies
            </Heading>

            <Button
              bg="secondary.600"
              _hover={{ bg: "secondary.700" }}
              color="white"
              fontSize={["md", "1.25rem"]}
              p={["1.25rem", "2rem"]}
              py={["1.75rem", "2rem"]}
              rightIcon={<Icon as={BsArrowUpRight} />}
              as={Link}
              to="/menu"
            >
              See Our Menu
            </Button>
          </GridItem>
          <GridItem>
            <Flex justifyContent="flex-end">
              <Box maxW="555px" w="full" rounded="10px" mt={["2.5rem", "0"]}>
                <Text
                  color="text.footerGray"
                  fontSize={["xl", "2xl"]}
                  fontWeight={300}
                  mb="1.5rem"
                >
                  We are open to your suggestions
                </Text>

                <Box
                  as="form"
                  onSubmit={handleSubmit(onSubmit)}
                  w="full"
                  bg="footerContactBg"
                  rounded="10px"
                >
                  <Box p="0.875rem">
                    <SimpleGrid columns={[1, 2]} gap="1rem">
                      <FormControl isInvalid={!!errors?.fullname}>
                        <Input
                          color="text.bodyGray"
                          fontSize={["sm", "1.25rem"]}
                          h="3rem"
                          border="none"
                          outline="none"
                          bg="footerInputBg"
                          placeholder="Name*"
                          _placeholder={{ color: "#B6B6B6" }}
                          {...register("fullname")}
                        />
                        <FormErrorMessage>
                          {errors?.fullname?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors?.email}>
                        <Input
                          color="text.bodyGray"
                          fontSize={["sm", "1.25rem"]}
                          h="3rem"
                          border="none"
                          outline="none"
                          bg="footerInputBg"
                          placeholder="Email*"
                          _placeholder={{ color: "#B6B6B6" }}
                          {...register("email")}
                        />
                        <FormErrorMessage>
                          {errors?.email?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </SimpleGrid>
                    <FormControl isInvalid={!!errors?.message}>
                      <Textarea
                        color="text.bodyGray"
                        fontSize={["sm", "1.25rem"]}
                        p="1.5rem"
                        h="7.1875rem"
                        border="none"
                        outline="none"
                        bg="footerInputBg"
                        {...register("message")}
                        placeholder="Type your message here"
                        _placeholder={{ color: "#B6B6B6" }}
                        mt="1rem"
                      />
                      <FormErrorMessage>
                        {errors?.message?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <HStack
                    justifyContent="flex-start"
                    borderTopWidth="1px"
                    borderTopColor="border.footer"
                    px="1rem"
                    py="0.875rem"
                  >
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      bg="header.grayBg"
                      _hover={{ bg: "secondary.200", color: "secondary.700" }}
                      color="white"
                      fontSize={["md", "1.25rem"]}
                      p={["1.25rem", "2rem"]}
                      py={["1.75rem", "2rem"]}
                      rightIcon={<Icon as={BsArrowUpRight} />}
                    >
                      Send Message
                    </Button>
                  </HStack>
                </Box>
              </Box>
            </Flex>
          </GridItem>
        </SimpleGrid>

        <SimpleGrid
          columns={[1, 5]}
          spacing={8}
          borderTopWidth="1.5px"
          borderTopColor="border.largeFooter"
          py="3.75rem"
          mt="3.75rem"
        >
          <GridItem>
            <Image
              mx={["auto", "0"]}
              src={Logo}
              alt="philly eatery logo"
              w="5.625rem"
              h="auto"
            />
            <HStack
              justifyContent={["center", "flex-start"]}
              mt="1.5rem"
              spacing="1.25rem"
            >
              <Center
                cursor="pointer"
                _hover={{ bg: "secondary.600" }}
                w="2.5rem"
                h="2.5rem"
                rounded="full"
                borderWidth="1px"
                borderColor="border.largeFooter"
                as={ChakraLink}
                href="https://instagram.com/eatwell.philly"
                target="_blank"
              >
                <Icon color="white" as={FaInstagram} fontSize="1rem" />
              </Center>
              <Center
                cursor="pointer"
                _hover={{ bg: "secondary.600" }}
                w="2.5rem"
                h="2.5rem"
                rounded="full"
                borderWidth="1px"
                borderColor="border.largeFooter"
                as={ChakraLink}
                href="tel: +1 215-964-9213"
              >
                <Icon color="white" as={IoCallOutline} fontSize="1rem" />
              </Center>
              <Center
                cursor="pointer"
                _hover={{ bg: "secondary.600" }}
                w="2.5rem"
                h="2.5rem"
                rounded="full"
                borderWidth="1px"
                borderColor="border.largeFooter"
                as={ChakraLink}
                href="mailto: food@eatwellphilly.com"
              >
                <Icon color="white" as={IoMailOutline} fontSize="1rem" />
              </Center>
            </HStack>
          </GridItem>

          <Box />
          {/* contact */}
          {/* <GridItem mt={["1rem", "0"]}>
            <Heading
              textAlign={["center", "left"]}
              color="white"
              fontWeight={500}
              fontSize={["2xl", "3xl"]}
            >
              Contact Us
            </Heading>
            <VStack mt={["1rem", "1.25rem"]} spacing="2">
              <Text
                as={ChakraLink}
                href="mailto: food@eatwellphilly.com"
                w="full"
                textAlign={["center", "left"]}
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
              >
                food@eatwellphilly.com
              </Text>
              <Text
                as={ChakraLink}
                href="tel: +1 215-964-9213"
                w="full"
                textAlign={["center", "left"]}
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
              >
                +1 215-964-9213
              </Text>
            </VStack>
          </GridItem> */}

          {/* Opening Hours */}
          <GridItem>
            <Heading
              color="white"
              fontWeight={500}
              fontSize={["2xl", "3xl"]}
              textAlign={["center", "left"]}
            >
              Opening Hours
            </Heading>
            <VStack
              mt={["1rem", "1.25rem"]}
              spacing={["4", "2"]}
              alignItems={["center", "flex-start"]}
            >
              <Text
                w="full"
                color="white"
                maxW={["180px"]}
                fontWeight="light"
                fontSize={["0.875rem", "1rem"]}
                textAlign={["center", "left"]}
              >
                Monday - Closed
              </Text>
              <Text
                w="full"
                color="white"
                maxW={["180px"]}
                fontWeight="light"
                fontSize={["0.875rem", "1rem"]}
                textAlign={["center", "left"]}
              >
                Tues - Thurs (12pm - 9pm)
              </Text>
              <Text
                w="full"
                color="white"
                maxW={["180px"]}
                fontWeight="light"
                fontSize={["0.875rem", "1rem"]}
                textAlign={["center", "left"]}
              >
                Fri - Sat (12pm - 11pm)
              </Text>
              <Text
                w="full"
                color="white"
                maxW={["180px"]}
                fontWeight="light"
                fontSize={["0.875rem", "1rem"]}
                textAlign={["center", "left"]}
              >
                Sun (1pm - 9pm)
              </Text>
            </VStack>
          </GridItem>

          {/* Links */}
          <GridItem>
            <Heading
              color="white"
              fontWeight={500}
              fontSize={["2xl", "3xl"]}
              textAlign={["center", "left"]}
            >
              Legals
            </Heading>
            <VStack mt={["1rem", "1.25rem"]} spacing={["2"]}>
              <Text
                as={Link}
                to="/privacy-and-policy"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "left"]}
              >
                Privacy Policy
              </Text>
              <Text
                as={Link}
                to="/terms-of-service"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "left"]}
              >
                Terms of Service
              </Text>
            </VStack>
          </GridItem>

          {/* Links */}
          <GridItem>
            <Heading
              color="white"
              fontWeight={500}
              fontSize={["2xl", "3xl"]}
              textAlign={["center", "right"]}
            >
              Quick Links
            </Heading>
            <VStack mt={["1rem", "1.25rem"]} spacing="2">
              <Text
                as={Link}
                to="/menu"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "right"]}
              >
                Menu
              </Text>
              <Text
                as={Link}
                to="/reservations"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "right"]}
              >
                Reservation
              </Text>
              <Text
                as={Link}
                to="/events"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "right"]}
              >
                Catering & Events
              </Text>
              <Text
                as={Link}
                to="/career"
                w="full"
                color="white"
                fontWeight="light"
                fontSize={["1rem", "1.25rem"]}
                textAlign={["center", "right"]}
              >
                Careers
              </Text>
            </VStack>
          </GridItem>
        </SimpleGrid>

        <Center flexDir="column">
          <Text
            color="text.footerText"
            fontSize={["0.875rem", "1.125rem"]}
            mb="1.25rem"
          >
            ©2023 Eatwell Philly. All Rights Reserved
          </Text>

          <HStack spacing={["1", "1"]}>
            <Text
              color="text.footerText"
              fontSize={["0.875rem", "1.125rem"]}
              mb={["0", "1.25rem"]}
            >
              Designed and developed by
            </Text>
            <Text
              as={ChakraLink}
              href="https://www.babskenky.com"
              target="_blank"
              textDecor="underline"
              color="text.footerText"
              fontSize={["0.875rem", "1.125rem"]}
              mb={["0", "1.25rem"]}
            >
              Babskenky & Company Inc.
            </Text>
          </HStack>
        </Center>
      </Container>
    </Box>
  );
};
