import React from 'react'
import { MainLayout } from '../layouts'
import { CareerHero } from '../features/career/CareerHero'
import { ApplicationSuccess } from '../features/career/ApplicationSuccess'

export const CareerSuccess = () => {
  return (
    <MainLayout>
        <CareerHero />
        <ApplicationSuccess />
    </MainLayout>
  )
}
