import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    bg: "#FF5400",
    color: "white",
    fontWeight: "regular",
    fontSize: "1.3rem",
    borderRadius: "2.25rem",
    px: "2rem",
    py: "1rem",
  },
  sizes: {
    xs: {
      color: "gray.800",
    },
    sm: {
      fontSize: "0.5625rem",
      height: "24px",
      borderRadius: "2.75px",
    },
  },
  variants: {
    link: {
      padding: 0,
      backgroundColor: "transparent",
      _hover: {
        textDecoration: "none",
      },
    },
    subtle: (props) => {
      return {
        backgroundColor: `${props.colorScheme}.50`,
        color: `${props.colorScheme}.700`,
      };
    },
  },
};
