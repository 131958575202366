import React, { useMemo } from "react";

import { FilterLists, MenuGrid } from "../../components";
import { Box, Center, Icon, IconButton, useMediaQuery } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { mealService } from "../../services/meal";
import { MealLoading } from "./MealLoading";
import { SelectedMealModal } from "../takeOut";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";

const filters: string[] = [
  "All",
  "Starters",
  "Authentic African Snacks",
  "Drinks",
  "Authentic Specials",
  "Sides",
  "Swallow",
  "Soups (No Meat Included)",
];

const PAGESIZE = 10;

export const MenuLists = () => {
  // const [filter, setFilter] = React.useState("All");
  const [page, setPage] = React.useState<number>(1);

  const isMobile = useMediaQuery("(max-width: 768px)")[0];

  const pageSize = useMemo(() => {
    if (isMobile) {
      return PAGESIZE;
    }
    return 20;
  }, [isMobile]);

  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter") || "All";

  const params = useMemo(() => {
    let filterParams: {
      pageSize: number;
      pageNumber: number;
      filter?: string;
    } = {
      pageSize: pageSize,
      pageNumber: page,
    };

    if (filter !== "All") {
      filterParams = {
        ...filterParams,
        filter: JSON.stringify({ categoryname: filter }),
      };
    }

    return filterParams;
  }, [filter, page, pageSize]);

  const { data: mealData, isLoading: isReadingAll } = useQuery({
    queryKey: ["meal", params],
    queryFn: () => mealService.readAllMeal(params),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const totalPages = React.useMemo(() => {
    if (mealData) {
      return Math.ceil(mealData.totalCount / pageSize);
    }
    return 1;
  }, [mealData, pageSize]);

  return (
    <Box>
      {/* filters */}
      <FilterLists filters={filters} setPage={setPage} />

      {/* menu */}
      <Box mt={["1.5rem", "5.25rem"]}>
        {isReadingAll ? (
          <MealLoading />
        ) : (
          <MenuGrid menu={mealData?.entities || []} />
        )}
      </Box>

      {
        totalPages > 1 ? (
          <Center gap="1rem" mt="2rem">
            <IconButton
              bg="orange.100"
              _hover={{ bg: "orange.400" }}
              aria-label="previous pagination button"
              icon={<Icon as={AiOutlineLeft} />}
              isDisabled={page === 1}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            />
            <IconButton
              bg="orange.100"
              _hover={{ bg: "orange.400" }}
              aria-label="next pagination button"
              isDisabled={page === totalPages}
              icon={<Icon as={AiOutlineRight} />}
              onClick={() => {
                if (page < totalPages) {
                  setPage(page + 1);
                }
              }}
            />
          </Center>
        ) : null
      }

      <SelectedMealModal />
    </Box>
  );
};
