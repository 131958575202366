import React from 'react'
import { LayoutWithHead } from '../layouts'
import { MenuLists } from '../features/menu/MenuLists'

export const Menu = () => {
  return (
    <LayoutWithHead title="Menu">
      <MenuLists />
    </LayoutWithHead>
  )
}
