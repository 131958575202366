import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from "./CustomDateInput";
import { format } from "date-fns";
import { useMutation } from "react-query";
import { reservationService } from "../../services/reservation";

interface FormField {
  label: string;
  placeholder: string;
  type: string;
  name:
    | "email"
    | "fullName"
    | "tableNumber"
    | "phoneNumber"
    | "reservationDate"
    | "reservationTime"
    | "note"
    | "reservationEndTime";
}

const formFields: FormField[] = [
  {
    label: "Full name",
    placeholder: "Full name",
    type: "text",
    name: "fullName",
  },
  {
    label: "Email",
    placeholder: "Email address",
    type: "email",
    name: "email",
  },
  {
    label: "Phone number",
    placeholder: "Phone number",
    type: "tel",
    name: "phoneNumber",
  },
  {
    label: "Nō of tables",
    placeholder: "Nō of tables",
    type: "number",
    name: "tableNumber",
  },
  {
    label: "Reservation date",
    placeholder: "Reservation date",
    type: "date",
    name: "reservationDate",
  },
  {
    label: "Reservation time",
    placeholder: "Reservation time",
    type: "time",
    name: "reservationTime",
  },
  {
    label: "Reservation End Time",
    placeholder: "Reservation End Time",
    type: "time",
    name: "reservationEndTime",
  },
  {
    label: "Note (Optional)",
    placeholder: "What would you want us to know for your reservation",
    type: "textarea",
    name: "note",
  },
];

const schema = Yup.object({
  fullName: Yup.string().min(2).max(30).required().label("Full Name"),
  email: Yup.string().email().required().label("Email Address"),
  phoneNumber: Yup.string().required().label("Phone Number"),
  tableNumber: Yup.number().required().label("Table Number"),
  reservationDate: Yup.date().required().label("Reservation Date"),
  reservationTime: Yup.date().required().label("Reservation Time"),
  reservationEndTime: Yup.date().required().label("Reservation End Time"),
  note: Yup.string().optional().label("Note"),
});

export type ReservationFormInputType = Yup.InferType<typeof schema>;

export const ReservationForm = () => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      tableNumber: 1,
      reservationDate: new Date(),
      reservationTime: new Date(),
      reservationEndTime: new Date(),
      note: "",
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();

  const {
    mutate: createReservation,
    isLoading: isCreatingReservation,
  } = useMutation(
    reservationService.createReservation,
    {
      onSuccess: () => {
        toast({
          title: "Reservation created successfully",
          description: "Thank you for choosing us",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
      },
      onError: (error: any) => {
        toast({
          title: "Error while creating reservation",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  )

  const onSubmit = (data: ReservationFormInputType) => {
    let param: any = {
      name: data.fullName,
      email: data.email,
      phone: data.phoneNumber,
      requested_table_count: data.tableNumber,
      reservationdate: format(data.reservationDate, "yyyy/MM/dd") as string,
      reservationtime: format(data.reservationTime, "hh:mmaa") + "-" + format(data.reservationEndTime, "hh:mmaa"),
    }

    if (data.note) {
      param = {
        ...param,
        note: data?.note,
      }
    }

    createReservation({
      ...param
    });
  };

  return (
    <VStack as="form" onSubmit={handleSubmit(onSubmit)} w="full">
      <SimpleGrid w="full" columns={[1, 2]} spacing="2rem">
        {formFields.map((field) => (
          <FormControl display={field.type !== "textarea" ? "block" : "none"}>
            {field.name === "reservationEndTime" ? null : (
              <FormLabel fontSize="2xl" color="border.largeFooter">
                {field.label}
              </FormLabel>
            )}
            {field.type !== "textarea" ? (
              <Box>
                {field.type === "number" ? (
                  <Controller
                    control={control}
                    name={field.name}
                    render={({ field: { ref, ...restField } }) => (
                      <NumberInput
                        onChange={(value) =>
                          setValue("tableNumber", Number(value))
                        }
                        h="5rem"
                      >
                        <NumberInputField
                          borderColor="border.darkInput"
                          borderWidth="0.5px"
                          color="border.largeFooter"
                          bg="filterBg"
                          h="5rem"
                          ref={ref}
                          name={restField.name}
                        />
                        <NumberInputStepper border="none">
                          <NumberIncrementStepper
                            borderWidth="0.5px"
                            borderColor="border.darkInput"
                            color="white"
                          />
                          <NumberDecrementStepper
                            borderColor="border.darkInput"
                            borderWidth="0.5px"
                            color="white"
                          />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                ) : field.name === "reservationEndTime" ? null : field.type ===
                  "time" ? (
                  <HStack pos="relative">
                    <Controller
                      name={field.name}
                      control={control}
                      render={({ field: { value } }) => (
                        <DatePicker
                          selected={value as Date}
                          placeholderText="Select date"
                          wrapperClassName="datePicker"
                          onChange={(date) => {
                            console.log(
                              "date",
                              date,
                              format(date as Date, "h:mmaaa")
                            );
                            setValue("reservationTime", date as Date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          customInput={<CustomDateInput isTime />}
                        />
                      )}
                    />
                    <Center
                      as="span"
                      px={2}
                      py={1}
                      rounded="md"
                      borderColor="border.darkInput"
                      borderWidth="0.5px"
                      color="filterBg"
                      fontWeight={700}
                      bg="border.largeFooter"
                      fontSize="sm"
                      pos="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      zIndex={4}
                    >
                      To
                    </Center>
                    <Controller
                      name={formFields[6].name}
                      control={control}
                      render={({ field: { value } }) => (
                        <DatePicker
                          selected={value as Date}
                          placeholderText="Select end time"
                          wrapperClassName="datePicker"
                          onChange={(date) => {
                            console.log(
                              "date",
                              date,
                              format(date as Date, "h:mmaaa")
                            );
                            setValue("reservationEndTime", date as Date);
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          customInput={<CustomDateInput isTime />}
                        />
                      )}
                    />
                  </HStack>
                ) : field.type === "date" ? (
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: { value } }) => (
                      <DatePicker
                        selected={value as Date}
                        placeholderText="Select date"
                        wrapperClassName="datePicker"
                        onChange={(date) =>
                          setValue("reservationDate", date as Date)
                        }
                        dateFormat="dd/MM/yyyy"
                        customInput={<CustomDateInput />}
                      />
                    )}
                  />
                ) : (
                  <Input
                    fontSize="xl"
                    fontWeight={300}
                    color="border.largeFooter"
                    _placeholder={{
                      color: "gray.700",
                    }}
                    bg="filterBg"
                    {...register(field.name)}
                    isInvalid={!!errors[field.name]}
                    h="5rem"
                    borderColor="border.darkInput"
                    borderWidth="0.5px"
                    placeholder={field.placeholder}
                    name={field.name}
                  />
                )}
              </Box>
            ) : null}
            {field.name === "reservationEndTime" ? null : (
              <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>
            )}
          </FormControl>
        ))}
      </SimpleGrid>

      <FormControl mt={6}>
        <FormLabel fontSize="2xl" color="border.largeFooter">
          {formFields[7].label}
        </FormLabel>
        {formFields[7].type === "textarea" ? (
          <Textarea
            fontSize="xl"
            fontWeight={300}
            h="13.5rem"
            color="border.largeFooter"
            _placeholder={{
              color: "gray.700",
            }}
            {...register(formFields[7].name)}
            isInvalid={!!errors[formFields[7].name]}
            bg="filterBg"
            borderColor="border.darkInput"
            borderWidth="0.5px"
            placeholder={formFields[7].placeholder}
            name={formFields[7].name}
            pt={8}
          />
        ) : null}
        <FormErrorMessage>
          {errors[formFields[7].name]?.message}
        </FormErrorMessage>
      </FormControl>

      <Box w="full" mt="2.5rem">
        <Button
          bg="secondary.600"
          isDisabled={!isValid || isCreatingReservation}
          isLoading={isCreatingReservation}
          _hover={{ bg: "secondary.700" }}
          color="white"
          fontSize={["0.875rem", "1.25rem"]}
          p={["1.25rem", "2rem"]}
          py={["1.5rem", "2rem"]}
          rightIcon={<Icon as={BiRightArrowAlt} />}
          type="submit"
        >
          Submit
        </Button>
      </Box>
    </VStack>
  );
};
