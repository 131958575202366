import { ModelService } from "./base";

export class SuggestionService extends ModelService<any> {
  constructor() {
    super("suggestion");
  }

  sendSuggestion = (payload: {
    message: string;
    fullname: string;
    email: string;
  }) => {
    return this.api
      .post<{ data: any }>(`/${this.modelName}`, payload)
      .then(({ data }) => data.data);
  };
}

export const suggestionService = new SuggestionService();
